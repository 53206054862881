
import { defineComponent, reactive, toRefs } from "vue";
import { MAP_KEY,MAP_NAME } from '@/utils/util'
import { dataType } from './stateType'

const states = {
    mapUrl: `https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=${MAP_KEY}&referer=${MAP_NAME}`
}
export default defineComponent({
  setup() {
    const state = reactive<dataType>(states)

    return {
        ...toRefs(state)
    };
  },
});
