import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3171ff97")
const _hoisted_1 = { class: "tencent-map-select-container" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      id: "mapPage",
      width: "100%",
      height: "100%",
      frameborder: 0,
      src: _ctx.mapUrl
    }, "您的浏览器不支持选点。Sorry", 8, _hoisted_2)
  ]))
}