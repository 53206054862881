
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { addResourceType } from "./addResourceType";
import ImageForm from "@/components/imageForm/ImageForm.vue";
import VideoForm from "@/components/videoForm/videoForm.vue";
import { getVarietyList } from "../../../../api/farmer";
import { dateFormat } from '@/utils/util'
import TencentMapSelect from '@/components/tencentMapSelect/TencentMapSelect.vue'

const states = {
  addResourceDialog: false,
  varietyOptions: [],
  selectedVariety: "",
  varietyData: {},
  showDateDialog: false,
  selectedMD:'',
  selectedDate:'',
  area:'',
  period:'',
  detail:'',
  yieldNumber:'',
  address:'',
  message: '',
  showMapSelect:false
};
export default defineComponent({
  setup() {
    const state = reactive<addResourceType>(states);
    const { onSelectVariety, onImage, onVideo, varietyChange } =
      useEvent(state);
    const { getVarietyDataList, confirmDate } = useNetwork(state);
    onMounted(() => {
      getVarietyDataList();
      
    });
    return {
      ...toRefs(state),
      onSelectVariety,
      onImage,
      onVideo,
      varietyChange,
      confirmDate,
    };
  },
  components: {
    ImageForm,
    VideoForm,
    TencentMapSelect
  },
});

/**
 * 其他工具函数hooks
 */
const useOtherFun = (state: addResourceType) => {
  const filterValues = function (list: Array<any>): Array<any> {
    list.forEach((item) => {
      if (item.values.length == 0) {
        delete item.values;
      } else {
        filterValues(item.values);
      }
    });
    return list;
  };
  return {
    filterValues,
  };
};

/**
 * 接口数据hooks
 */
const useNetwork = (state: addResourceType) => {
  const getVarietyDataList = async () => {
    const result: any = await getVarietyList();
    if (result.data.code == 0) {
      const { filterValues } = useOtherFun(state);
      state.varietyOptions = filterValues(result.data.data);
    }
  };

  const confirmDate = (e:any) => {
    state.selectedDate = dateFormat(e).MM + '-'+ dateFormat(e).DD
    state.showDateDialog = false
  }

  return {
    getVarietyDataList,
    confirmDate
  };
};

/**
 * 事件类hooks
 */
const useEvent = (state: addResourceType) => {
  const onSelectVariety = () => {
    state.addResourceDialog = true;
  };
  const onImage = () => {
    console.log("123123");
  };
  const onVideo = () => {
    console.log("234242424");
  };
  const varietyChange = (e: any) => {
    if (e.selectedOptions.length == 3) {
      state.varietyData = e.selectedOptions[e.selectedOptions.length - 1];
      state.addResourceDialog = false;
    }
  };
  return {
    onSelectVariety,
    onImage,
    onVideo,
    varietyChange,
  };
};
