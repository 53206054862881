
import { defineComponent, reactive, SetupContext } from "vue";
import { dataType } from "./stateType";
const useEvent = (state: dataType, context?: SetupContext) => {
  const onClickMore = () => {
    context && context.emit("click");
  };
  return {
    onClickMore,
  };
};
const states = {};
export default defineComponent({
  setup(props, context: SetupContext) {
    const state = reactive<dataType>(states);

    const { onClickMore } = useEvent(state, context);
    return {
      onClickMore,
    };
  },
});
